import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ResponseCode } from '../enum/response-code.enum';
import { Proyecto } from '../interfaces/proyecto';
import { ProyectoService } from '../services/proyecto.service';
import { TranslateDataService } from '../services/translate-data.service';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.scss']
})
export class ProyectosComponent implements OnInit {
  errorMessage: any;
  data: Proyecto[];
  dataEs: Proyecto[];
  dataEn: Proyecto[];
  translated = false;
  groups = [];
  selectedItemId: string;
  intentos = 5;

  constructor( private dataService: ProyectoService,
               private dataTranslator: TranslateDataService,
               private translate: TranslateService, ) { }

  ngOnInit(): void {
    this.setPageData();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => this.changeLang(event.lang));
  }

  async getAll() {
    return await this.dataService._find({query: {sortBy: 'date'}}).toPromise().then((res: any) => {
      const { content, messageCode } = res;
      console.log(res);
      if (messageCode && messageCode === ResponseCode.OK) {
        if (content[0].date > content[content.length - 1].date) {
          content.reverse();
        }

        const array = content;
        const map = new Map();
        for (const item of array) {
          if ( !map.has(item.date) ){
              map.set(item.date, true);    // set any value to Map
              this.groups.push(item.date);
          }
        }
        this.groups.reverse();
        return content;
      } else {
        this.intentos --;
        if (this.intentos){
          this.getAll();
        }
      }
    }).catch( (err: any) => {
      this.intentos --;
      if (this.intentos){
        this.getAll();
      }
      console.error(err);
      throw Error(err);
    });
  }

  async setPageData() {
    this.dataEs = await this.getAll();
    this.data = [];
    this.dataEn = [];
    this.dataEs.forEach( item => {
      this.data.push(Object.assign({}, item));
      this.dataEn.push(Object.assign({}, item));
    });
    if (this.translate.currentLang === 'en'){
      this.changeLang('en');
    }
  }

  async tarsnslate(){
    return await this.dataTranslator.translateDataSet(this.dataEn).then(() => this.translated = true);
  }

  async changeLang(lang){
    if (lang === 'en'){
      if ( !this.translated ){
        this.tarsnslate().then( res => {
          this.data = [];
          this.dataEn.forEach( item => {
            this.data.push(Object.assign({}, item));
          });
        });
      } else {
        this.data = [];
        this.dataEn.forEach( item => {
          this.data.push(Object.assign({}, item));
        });
      }
    } else if (lang === 'es') {
      this.data = [];
      if ( typeof this.dataEs !== 'undefined') {
        this.dataEs.forEach( item => {
          this.data.push(Object.assign({}, item));
        });
      }
    }
  }
}
