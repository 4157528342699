// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  client_id: '505756',
  API: {
    AUTH: 'https://2ajvkz18ua.execute-api.us-east-1.amazonaws.com/dev/palma/api/v1/',
    // AUTH: 'http://localhost:3000/dev/palma/api/v1/',
    CONTENT: 'https://3o1k3t779a.execute-api.us-east-1.amazonaws.com/dev/palma/api/v1/',
    // CONTENT: 'http://localhost:3000/dev/palma/api/v1/',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
