import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { environment } from '../../../environments/environment';
import { HttpService } from 'src/app/services/http.service';
import { ResponseCode } from '../../enum/response-code.enum';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  loading = false;
  errorMessage: any;
  redirectTo: string;

  constructor(
    private router: Router,
    private http: HttpService,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParamMap.subscribe((param) => {
      this.redirectTo = param.get('redirect');
    });
   }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }
  get logincontrol() { return this.loginForm.controls; }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      if (this.loginForm.value.username.trim() !== '' &&
        this.loginForm.value.password.trim() !== '') {
        this.loading = true;
        const user = this.loginForm.value;
        this.http.post(environment.API.AUTH, 'auth/login', user, false).subscribe((res: any) => {
          const { content, messageCode } = res;
          if (messageCode && messageCode === ResponseCode.OK) {
            this.handleLoginSuccess(content);
            this.loading = false;
          }
        }, (err) => {
          this.loading = false;
          const { message, messageCode } = err.error;
          this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Something went wrong...' : message;
        });
      }
    }
  }

  handleLoginSuccess(content) {
    this.userService.user = content.user;
    const navigateTo = this.redirectTo ? this.redirectTo : '/admin';
    localStorage.palma_token = content.token;
    this.router.navigate([navigateTo]).then(() => {
      this.loading = false;
      this.loginForm.reset();
    });
  }

  forgetpassword() {
    this.router.navigate(['/forgot']);
  }
}
