import * as S3 from 'aws-sdk/clients/s3';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Proyecto } from 'src/app/interfaces/proyecto';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { ResponseCode } from 'src/app/enum/response-code.enum';
import { S3Object } from 'src/app/interfaces/s3object';
import { SelectItem } from 'primeng/api';
import { SeoService } from 'src/app/services/seo.service';
import { UploadService } from 'src/app/services/upload.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.scss']
})
export class ProyectosAdminComponent implements OnInit {
  cols: any[];
  data: Proyecto[];
  displayDialog: boolean;
  errorMessage: any;
  exportColumns: any[];
  filteredResults: S3Object[];
  imageSearchText: string;
  item: Proyecto;
  loading = false;
  newProyecto: boolean;
  portada: any;
  infoReadonly = true;
  s3objects: S3Object[];
  selectedData: Proyecto;
  sortKey: string;
  sortOptions: SelectItem[];

  @ViewChild('portadaUpload') portadaUpload: any;
  @ViewChild('fotosUpload') fotosUpload: any;

  constructor(public userService: UserService,
    private seoService: SeoService,
    private uploadService: UploadService,
    private router: Router,
    private dataService: ProyectoService) { }

  ngOnInit(): void {
    this.seoService.setTitle('Palma | Administrador');
    this.getAll();

    this.cols = [
      { field: 'portada.thumbnail', header: 'Portada' },
      { field: 'titulo', header: 'Titulo' },
      { field: 'date', header: 'Año' },
      { field: 'oculto', header: 'Oculto' },
      { field: 'slug', header: 'Controles' },
    ];

    this.exportColumns = [
      { dataKey: 'portada.thumbnail', title: 'Portada' },
      { dataKey: 'titulo', title: 'Titulo' },
      { dataKey: 'descripcion', title: 'Descripción' },
      { dataKey: 'slug', title: 'Slug' },
    ];

    this.sortOptions = [
      { label: 'A a Z', value: 'Key' },
      { label: 'Z a A', value: '!Key' },
      { label: 'Chico a grande', value: 'Size' },
      { label: 'Grade a chico', value: '!Size' },
      { label: 'Recientes a antiguas', value: 'LastModified' },
      { label: 'Antiguas a Recientes', value: '!LastModified' }
    ];

    const user = this.userService.user;

    if (!user) {
      console.error('Unatorized access.');
    }

    const secretAccessKey = user?.credentials?.a?.split('').reverse().join('');

    const bucket = new S3(
      {
        accessKeyId: 'AKIA' + user?.credentials?.b,
        secretAccessKey: secretAccessKey
      }
    );

    const params = {
      Bucket: 'palma-assets',
    };

    const onListObjects = (err, s3data) => {
      if (err) {
        console.error('Error', err);
      } else {
        this.s3objects = s3data.Contents;
      }
    };

    bucket.listObjects(params, onListObjects);
  }

  getAll() {
    this.dataService.find({ query: { sortBy: 'created_at', asc: false } }).subscribe((res: any) => {
      const { content, messageCode } = res;
      if (messageCode && messageCode === ResponseCode.OK) {
        this.data = content;
      }
    }, (err) => {
      const { message, messageCode } = err.error;
      this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
    });
  }

  goTo(slug) {
    this.router.navigate(['proyectos/' + slug]);
  }


  // Edit form
  async save() {
    const data = [...this.data];

    // Upload images
    if (this.portadaUpload._files.length || this.fotosUpload._files.length) {
      this.loading = true;
      const file = this.portadaUpload._files[0];
      const files = this.fotosUpload._files;
      const promises = [];
      promises.push(
        this.uploadService.uploadFile(file, 'proyecto').then((response: any) => {
          const original = response.url;
          const thumbnail = this.uploadService.getVariant(response.key, { resize: 'inside', width: 100, height: 100 });
          this.item.portada = { original, thumbnail };
          this.portadaUpload.clear();
          this.fotosUpload.clear();
        }, err => {
          console.error(err);
          this.loading = false;
        })
      );

      files.forEach(foto => {
        promises.push(
          this.uploadService.uploadFile(foto, 'proyecto').then((response: any) => {
            this.item.fotos.push(response.url);
          }, err => {
            console.error(err);
            this.loading = false;
          }));
      });

      await Promise.all(promises).then(values => {
        this.portadaUpload.clear();
        this.fotosUpload.clear();
        this.loading = false;
      });
    } else if (!this.item.portada || this.item.portada.original === '') {
      this.item.portada = { original: '/assets/images/icons/proyectos.svg', thumbnail: '/assets/images/icons/proyectos.svg' };
    }

    // Save New
    if (this.newProyecto) {
      this.dataService.save(this.item).subscribe((res: any) => {
        const { content, messageCode } = res;
        if (messageCode && messageCode === ResponseCode.OK) {
          data.push(content.content);
          this.data = data;
          this.item = null;
          this.displayDialog = false;
        }
      }, (err) => {
        const { message, messageCode } = err.error;
        this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
      });
    } else {

      // Update
      this.dataService.update(this.item._id, this.item).subscribe((res: any) => {
        data[this.data.indexOf(this.selectedData)] = this.item;
        this.data = data;
      }, (err) => {
        this.getAll();
        const { message, messageCode } = err.error;
        this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
      });

      this.displayDialog = false;
    }
  }

  deleteById(id) {
    this.dataService.delete(id).subscribe((res: any) => {
      this.data = this.data.filter((item) => item._id !== id);
    }, (err) => {
      this.data = this.data.filter((item) => item._id !== id);
      const {
        message,
        messageCode
      } = err.error;
      this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
    });
  }

  delete() {
    this.deleteById(this.item._id);
    this.displayDialog = false;
  }

  showDialogToAdd() {
    this.newProyecto = true;
    this.item = {
      portada: { original: '/assets/images/icons/proyectos.svg', thumbnail: '/assets/images/icons/proyectos.svg' },
      fotos: []
    } as unknown as Proyecto;
    this.displayDialog = true;
  }

  onRowSelect(event) {
    this.newProyecto = false;
    this.item = this.cloneProyecto(event.data);
    this.displayDialog = true;
  }

  cloneProyecto(c: Proyecto): Proyecto {
    const proyecto = {} as Proyecto;

    for (const prop in c) {
      if (c.hasOwnProperty(prop)) {
        proyecto[prop] = c[prop];
      }
    }

    return proyecto;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  // Prevent editor autofocus
  detalleOnShow() {
    this.infoReadonly = false;
  }

  // Image management
  onUpload(event) {
    for (const file of event.files) {
      this.uploadService.uploadFile(file, 'crud-test').then((data: any) => {
        const original = data.url;
        const thumbnail = this.uploadService.getVariant(data.key, { resize: 'inside', height: 100, width: 100 });
        this.item.portada = { original, thumbnail };

      }, err => {
        console.error(err);
      });
    }

    this.portadaUpload.clear();
  }

  search($event) {
    this.filteredResults = [];
    for (const s3object of this.s3objects) {
      if (s3object.Key.toLowerCase().indexOf($event.query.toLowerCase()) !== -1) {
        this.filteredResults.push(s3object);
      }
    }
  }

  searchDone($event) {
    this.selectImage($event);
  }

  searchDone2($event) {
    this.selectImage2($event);
  }

  selectImage(image: S3Object) {
    const original = this.uploadService.getVariant(image.Key, {});
    const thumbnail = this.uploadService.getVariant(image.Key, { resize: 'inside', height: 100, width: 100 });
    this.item.portada = { original, thumbnail };
  }

  selectImage2(image: S3Object) {
    this.item.fotos.push(this.uploadService.getVariant(image.Key, {}));
  }

  onSortChange() {
    if (this.sortKey.indexOf('!') === 0) {
      this.sort(-1);
    }
    else {
      this.sort(1);
    }
  }

  sort(order: number): void {
    const s3objects = [...this.s3objects];
    const property = this.sortKey.replace('!', '');
    s3objects.sort((data1, data2) => {
      const value1 = data1[property];
      const value2 = data2[property];
      const result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      return (order * result);
    });

    this.s3objects = s3objects;
  }

  deletePortada() {
    this.item.portada = { original: '/assets/images/icons/proyectos.svg', thumbnail: '/assets/images/icons/proyectos.svg' };
  }

  deleteFoto(i) {
    this.item.fotos.splice(i, 1);
  }

  clearCache() {
    this.dataService.clearCache();
    this.getAll();
  }

}
