import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { Buffer } from 'buffer';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  cdnURL = 'https://d5if3g7wj9vwk.cloudfront.net/';

  constructor(
    private userService: UserService,
  ) { }

  async uploadFile(file, category = '', optios = {}) {

    const that = this;
    const promise = new Promise((resolve, reject) => {
      const contentType = file?.type;
      const user = this.userService.user;

      if (!user) {
        console.error('Unatorized access.');
        return reject(false);
      }
      const bucket = new S3(
        {
          accessKeyId: 'AKIA' + user?.credentials?.b,
          secretAccessKey: user?.credentials?.a?.split('').reverse().join('')
        }
      );

      const params = {
        Bucket: 'palma-assets',
        Key: category + '_' + Date.now().toString() + '-' + file.name,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType
      } as any;


      // for upload progress
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        // that.messageService.exchange({ source: 'UPLOAD_PROGRESS', data: Math.ceil((evt.loaded / evt.total) * 100) })
      }).send((err, data) => {
        if (err) {
          reject(err);
        }
        const response = { url: this.getVariant(data.key, {}), key: params.Key };
        resolve(response);
      });
    });

    return promise;
  }

  async uploadFileBase(file: any, thumbnail = true, original = true, sizes = ['400x400', '50x50']) {

    const that = this;
    const promise = new Promise((resolve, reject) => {
      const buf = new Buffer(file.imageData.replace(/^data:image\/\w+;base64,/, ''), 'base64');
      const contentType = 'image/png';

      const user = this.userService.user;
      if (!user) {
        console.error('Unatorized access.');
        return reject(false);
      }
      const bucket = new S3(
        {
          accessKeyId: 'AKIA' + user?.credentials?.b,
          secretAccessKey: user?.credentials?.a?.split('').reverse().join('')
        }
      );

      const params = {
        Bucket: 'palma-assets',
        Key: Date.now().toString() + '.png',
        Body: buf,
        ACL: 'public-read',
        ContentEncoding: 'base64',
        ContentType: contentType
      };
      bucket.upload(params).on('httpUploadProgress', (evt) => {
      }).send((err, data) => {
        if (err) {
          console.error('There was an error uploading your file: ', err);
          reject(err);
        }

        const response = that.getImageVariant(data, thumbnail, original, sizes);
        resolve(true);
      });
    });

    return promise;
  }

  getImageVariant(data: any, thumbnail = true, original = true, sizes = ['400x400', '50x50']) {
    let imageObject;
    imageObject = {};

    if (!data) {
      return undefined;
    }
    if (thumbnail) {
      imageObject.thumbnail = this.cdnURL + '200x200/filters:stretch()/' + data.key;
    }
    if (original) {
      imageObject.original = data.Location;
    }
    imageObject.path = this.cdnURL + '/' + data.key;
    sizes.map(s => {
      imageObject['s' + s] = this.cdnURL + s + '/filters:stretch()/' + data.key;
    });

    return imageObject;
  }

  getVariant(name, options) {
    // Gather the editor inputs
    const config = {
      width: '',
      height: '',
      resize: 'Disabled',
      fillColor: '',
      backgroundColor: '',
      grayscale: false,
      flip: false,
      flop: false,
      negative: false,
      flatten: false,
      normalize: false,
      rgb: '',
      smartCrop: false,
      smartCropIndex: '',
      smartCropPadding: '0'
    };

    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        config[key] = options[key];
      }
    }

    // Setup the edits object
    const edits = {} as any;
    edits.resize = {};
    if (config.resize !== 'Disabled') {
      if (config.width !== '') { edits.resize.width = Number(config.width); }
      if (config.height !== '') { edits.resize.height = Number(config.height); }
      edits.resize.fit = config.resize;
    }
    if (config.fillColor !== '') { edits.resize.background = this.hexToRgbA(config.fillColor, 1); }
    if (config.backgroundColor !== '') { edits.flatten = { background: this.hexToRgbA(config.backgroundColor, undefined) }; }
    if (config.grayscale) { edits.grayscale = config.grayscale; }
    if (config.flip) { edits.flip = config.flip; }
    if (config.flop) { edits.flop = config.flop; }
    if (config.negative) { edits.negate = config.negative; }
    if (config.flatten) { edits.flatten = config.flatten; }
    if (config.normalize) { edits.normalize = config.normalize; }
    if (config.rgb !== '') {
      const input = config.rgb.replace(/\s+/g, '');
      const arr = input.split(',');
      const rgbArray = { r: Number(arr[0]), g: Number(arr[1]), b: Number(arr[2]) };
      edits.tint = rgbArray;
    }
    if (config.smartCrop) {
      edits.smartCrop = {};
      if (config.smartCropIndex !== '') { edits.smartCrop.faceIndex = Number(config.smartCropIndex); }
      if (config.smartCropPadding !== '') { edits.smartCrop.padding = Number(config.smartCropPadding); }
    }
    if (Object.keys(edits.resize).length === 0) { delete edits.resize; }
    // Gather the bucket and key names
    const bucketName = 'palma-assets';
    const keyName = name;

    // Set up the request body
    const request = {
      bucket: bucketName,
      key: keyName,
      edits
    };
    if (Object.keys(request.edits).length === 0) { delete request.edits; }

    // Setup encoded request
    const str = JSON.stringify(request);
    const enc = btoa(str);
    return (this.cdnURL + enc);
  }

  hexToRgbA(hex, alpha) {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      // tslint:disable-next-line:no-bitwise
      return { r: ((c >> 16) & 255), g: ((c >> 8) & 255), b: (c & 255), alpha: Number(alpha) };
    }
    throw new Error('Bad Hex');
  }
}
