<form class="grid-x grid-margin-y grid-padding-x ui-fluid app__scrollable">
    <div class="cell small-12 large-9">
        <p-fileUpload #imagenUpload name="imagenUpload" customUpload="true" maxFileSize="5000000" fileLimit="20"
          accept="image/*" chooseLabel="&nbsp;Subir" cancelLabel="&nbsp;Cancelar" [showUploadButton]="false">
        </p-fileUpload>
      </div>
      <div class="cell small-6 medium-4 large-2">
        <img [src]="pagina?.imagen" alt="Palma" class="p-shadow-4"  />
      </div>
      <div class="cell shrink">
        <button type="button" pButton icon="pi pi-times" (click)="deleteImagen()"></button>
    </div>
    <div class="cell">
        <p-panel header="Elegir imagen de galeria de medios" [toggleable]="true" [collapsed]="true">
            <p-virtualScroller #virtualScroller [value]="s3objects" scrollHeight="50vh" [lazy]="true"  [rows]="5" [itemSize]="120" >
            <p-header>
                <div class="sort-container">
                <div class="grid-x grid-padding-x">
                    <div class="cell medium-8">
                    <p-autoComplete name="imageSearch" [(ngModel)]="imageSearchText" [suggestions]="filteredResults" field="Key"
                        (completeMethod)="search($event)" (onSelect)="searchDone($event)" placeholder="Buscar por nombre"></p-autoComplete>
                    </div>
                    <div class="cell medium-4">
                    <p-dropdown name="dropdown1SortBy" [options]="sortOptions" [(ngModel)]="sortKey"
                        placeholder="Ordenar" (onChange)="onSortChange()" [style]="{'min-width':'140px'}"></p-dropdown>
                    </div>
                </div>
                </div>
            </p-header>
            <ng-template let-s3object pTemplate="item" let-i="index">
                <div class="grid-x grid-padding-x align-middle">
                <div class="cell medium-3">
                    <img class="gallery-pick__image" src="https://palma-assets.s3.amazonaws.com/{{s3object.Key}}">
                </div>
                <div class="cell medium-6" style="overflow: hidden; text-overflow: ellipsis;">
                    <strong>{{s3object.Key}}</strong>
                </div>
                <div class="cell auto">
                    <strong>{{s3object.LastModified |  date:'d/M/yy, h:mm a' }}</strong>
                </div>
                <div class="cell shrink">
                    <button type="button" pButton icon="pi pi-check" (click)="selectImage(s3object)"></button>&nbsp;
                </div>
                </div>
            </ng-template>
            <ng-template let-car pTemplate="loadingItem">
            </ng-template>
            </p-virtualScroller>
        </p-panel>
    </div>
    <div class="cell">
        <p-editor id="contenido" name="contenido" [(ngModel)]="pagina.contenido">
        </p-editor>
    </div>
    <div class="cell text-right">
        <button (click)="save()" class="button">Guardar</button>
    </div>
</form>