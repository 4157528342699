import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Noticia } from 'src/app/interfaces/noticia';
import { NoticiasService } from 'src/app/services/noticia.service';
import { ResponseCode } from 'src/app/enum/response-code.enum';
import { TranslateDataService } from '../../services/translate-data.service';
import { error } from 'protractor';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss']
})
export class NoticiasComponent implements OnInit {
  data: Noticia[];
  dataEs: Noticia[];
  dataEn: Noticia[];
  translated = false;
  errorMessage: any;
  loading = true;

  constructor(private dataService: NoticiasService,
              private dataTranslator: TranslateDataService,
              private translate: TranslateService,  ) { }

  ngOnInit(): void {
    this.setPageData();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => this.changeLang(event.lang));
  }

  async getAll() {
    return await this.dataService._find({query: {oculto: { $ne: true }, sortBy: 'sortDate', asc: false}}).toPromise().then((res: any) => {
      const { content, messageCode } = res;
      if (messageCode && messageCode === ResponseCode.OK) {
        return content;
      }
    }).catch( (err: any) => {
      console.error(err);
      throw Error(err);
    });
  }

  async setPageData() {
    this.dataEs = await this.getAll();
    this.data = [];
    this.dataEn = [];
    this.dataEs.forEach( item => {
      this.data.push(Object.assign({}, item));
      this.dataEn.push(Object.assign({}, item));
    });
    if (this.translate.currentLang === 'en'){
      this.changeLang('en');
    }
  }

  async tarsnslate(){
    return await this.dataTranslator.translateDataSet(this.dataEn).then(() => this.translated = true);
  }

  async changeLang(lang){
    if (lang === 'en'){
      if ( !this.translated ){
        this.tarsnslate().then( res => {
          this.data = [];
          this.dataEn.forEach( item => {
            this.data.push(Object.assign({}, item));
          });
        });
      } else {
        this.data = [];
        this.dataEn.forEach( item => {
          this.data.push(Object.assign({}, item));
        });
      }
    } else if (lang === 'es') {
      this.data = [];
      if ( typeof this.dataEs !== 'undefined') {
        this.dataEs.forEach( item => {
          this.data.push(Object.assign({}, item));
        });
      }
    }
  }

}
