import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../services/http.service';
import { UserService } from '../services/user.service';

@Component({
  template: '',
})

export class LogoutComponent implements OnInit {

  constructor(  private router: Router,
                public userService: UserService,
                public http: HttpService ) { }

  ngOnInit(): void {
    localStorage.removeItem('token');
    this.userService.user = null;
    this.router.navigate(['/iniciar-sesion']);
  }

}
