import * as $ from 'jquery';
import { Component, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './services/user.service';
@Component ({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
      })),
      state('closed', style({
        opacity: 0,
      })),
      transition('open => closed', [
        animate('.2s')
      ]),
      transition('closed => open', [
        animate('.2s')
      ]),
    ]),
  ],
})

export class AppComponent implements OnInit, AfterViewInit {
  title = 'Palma-UI';
  admin = false;
  logo: string;
  e: Event;
  trigger: any;
  MotionUI: any;
  isOpen = true;
  contacto = false;
  gifTimer = 30;
  scrollProyectos = 0;
  aplyScroll = false;
  debug: any;

  constructor(private router: Router,
              public translate: TranslateService,
              public userService: UserService){
                translate.addLangs(['en', 'es']);
                translate.setDefaultLang('es');
                translate.use('es');
              }

  ngOnInit(): void {
    this.setVh();
    const offCanvas = new Foundation.OffCanvas($('#offCanvas'));
    this.getLogo();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.aplyScroll ) {
        this.aplyScroll = false;
        setTimeout(() => {
          document.getElementById('listado-proyectos').scroll({top: this.scrollProyectos, behavior: 'smooth'});
        }, 200);
      }

      if (event instanceof RoutesRecognized) {
        this.getLogo();

        if (event.urlAfterRedirects.includes('proyectos') && this.router.url.match('/proyectos\/.+') && !event.urlAfterRedirects.includes('(mas:')) {
          this.aplyScroll = true;
        }

        if (event.urlAfterRedirects.match('/proyectos\/.+') && this.router.url.includes('proyectos') && !event.urlAfterRedirects.includes('(mas:')) {
          this.scrollProyectos = document.getElementById('listado-proyectos').scrollTop;
        }

        if (!event.urlAfterRedirects.includes('proyectos')) {
          this.scrollProyectos = 0;
        }

        if ( event.urlAfterRedirects.includes('admin') ){
          this.admin = true;
        } else {
          this.admin = false;
        }

        if ( event.urlAfterRedirects.includes('(mas:') ){
          offCanvas.open(this.e, this.trigger);
        }

        if ( event.urlAfterRedirects.includes('(mas:contacto)') ){
          this.contacto = true;
        } else {
          this.contacto = false;
        }
      }
    });

    $('#offCanvas').on('close.zf.offCanvas', event => {
      this.isOpen = false;
      this.getLogo();
      this.router.navigate(['.', {outlets: {mas: null}}]);
    });

    $('#offCanvas').on('opened.zf.offCanvas', event => {
      this.isOpen = true;
    });

    if (!this.admin){
      setInterval(() => { this.gifTimer--; }, 1000);
    }
  }

  ngAfterViewInit(): void {
    this.setGif();
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  scrollTop(){
    document.getElementById('offCanvas_scrollable').scroll({top: 0, behavior: 'smooth'});
  }

  @HostListener('window:resize', ['$event']) onResize(e) {
    this.setVh();
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  @HostListener('document:touchend', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('document:scroll', ['$event']) setGif( ) {
    this.gifTimer = 30;

    if (!this.admin){
      this.gifTimer = 30;
      const gif = document.getElementById('app__rupestre-container');
      const w = window.innerWidth;
      const h = window.innerHeight;

      const x = Math.floor(Math.random() * (w * 0.6)) + w * 0.05 + 'px';
      const y = Math.floor(Math.random() * (h * 0.8)) + h * 0.05 + 'px';

      gif.style.left = x;
      gif.style.top = y;
      gif.style.width =  Math.floor(Math.random() * 40) + 50 + '%';
    }
  }

  setVh(){
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.debug = { innerHeight: window.innerHeight, vh};
  }

  getLogo() {
    const logos = [
      'logo-Palma-a.svg',
      'logo-Palma-a2.svg',
      'logo-Palma-m.svg',
      'logo-Palma-p.svg',
      'logo-Palma-rupestre.svg',
    ];

    this.logo = logos[Math.floor(Math.random() * logos.length)];
  }
}

