import { Injectable } from '@angular/core';
import { ResponseCode } from '../enum/response-code.enum';
import { Traduccion } from '../interfaces/traduccion';
import { TraduccionesService } from './traducciones.service';

@Injectable({
  providedIn: 'root'
})
export class TranslateDataService {

  constructor( private dataService: TraduccionesService) { }

  public async translateDataSet(data: any[]){
    const promises = [];
    data.forEach( item => promises.push( this.translateItem(item)));
    return await Promise.all(promises).then(() => true).catch( err => { throw(err); });
  }

  public async translateItem(data: any): Promise<any>{
    return await this.dataService._find({query: {origen: data._id}}).toPromise().then((res: any) => {
      const { content, messageCode } = res;
      if (messageCode && messageCode === ResponseCode.OK) {
        const item = this.aplyTanslation(data, content as [Traduccion]);
        return item;
      }
    }).catch( err => {
      console.error(err);
      return data;
    });
  }

  aplyTanslation(item, translations: [Traduccion]){
    translations.forEach( traduccion => {
      item[traduccion.campo] = traduccion.contenido;
    });
    return item;
  }
}
