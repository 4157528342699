import { Component, OnInit } from '@angular/core';
import { ResponseCode } from 'src/app/enum/response-code.enum';
import { Pagina } from 'src/app/interfaces/pagina';
import { PaginaService } from 'src/app/services/pagina.service';
import { TranslateDataService } from '../../services/translate-data.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  pagina: Pagina;
  paginaEs: Pagina;
  paginaEn: Pagina;
  errorMessage: any;
  loading = true;

  constructor(private dataService: PaginaService,
              private dataTranslator: TranslateDataService,
              private translate: TranslateService ) { }

  ngOnInit(): void {
    this.setPageData();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => this.changeLang(event.lang));
  }

  async getpagina(){
    return await this.dataService._findOne({_id: '5f77c9cd18eb196a50be8f9c'}).toPromise().then( (res: any) => {
      const { content, messageCode } = res;
      if (messageCode && messageCode === ResponseCode.OK) {
        this.loading = false;
        return content;
      }
    }).catch( err => {
      const { message, messageCode } = err.error;
      this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
      throw Error(err);
    });
  }

  async setPageData() {
    this.paginaEs = await this.getpagina();
    if (this.translate.currentLang === 'en'){
      this.changeLang('en');
    } else {
      this.pagina = Object.assign({}, this.paginaEs);
    }
  }

  changeLang(lang){
    if (lang === 'en'){
      if (typeof this.paginaEn !== 'undefined'){
        this.pagina = Object.assign({}, this.paginaEn);
      } else {
        this.paginaEn = Object.assign({}, this.paginaEs);
        this.dataTranslator.translateItem(this.paginaEn).then(() =>
          this.pagina = Object.assign({}, this.paginaEn)
        );
      }
    } else if (lang === 'es') {
      this.pagina = Object.assign({}, this.paginaEs);
    }
  }
}
