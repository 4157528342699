<div class="grid-container padding-top-40-80">
    <div class="grid-x grid-margin-x grid-margin-y">
        <div class="cell medium-6">
        </div>

        <div class="cell medium-5">
            <form [formGroup]="signupForm" (ngSubmit)="signUp()">
                <div class="grid-x grid-margin-x align-right">
                    <div class="cell">
                        <h5>Registro</h5>
                    </div>
                    <div class="cell">
                        <input type="text" formControlName="firstname" class="form-control " placeholder="Nombre"
                            [ngClass]="{ 'is-invalid': submitted && signupControl.firstname.errors }">
                        <div *ngIf="submitted && signupControl .firstname.errors" class="alert callout">
                            <div *ngIf="signupControl .firstname.errors.required">
                                El Nombre es requerido
                            </div>
                        </div>
                    </div>
                    <div class="cell">
                        <input type="password" formControlName="password" class="form-control " placeholder="Password"
                            [ngClass]="{ 'is-invalid': submitted && signupControl.password.errors }">
                        <div *ngIf="submitted && signupControl.password.errors" class="alert callout">
                            <div *ngIf="signupControl.password.errors.required">
                                La contraseña es requerida
                            </div>
                            <div *ngIf="signupControl.password.errors.minlength">
                                La contraseña debetener al menos 6 caracters
                            </div>
                            <div *ngIf="signupControl.password.errors.pattern">
                                La contraseña debe tener letras y numeros
                            </div>
                        </div>
                    </div>
                    <div class="error">
                        {{errorMessage}}
                    </div>

                    <div class="cell shrink">
                        <button class="button hollow" [routerLink]="['/login']">Iniciar sesion</button>
                    </div>
                    <div class="cell shrink">
                        <button type="submit" class="button sign_in">Registrarse<i class="fa fa-spinner fa-spin ml-2"
                                *ngIf="loading"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>