import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from '../../environments/environment';
import { RequestCache } from './request-cache.service';


@Injectable({
  providedIn: 'root'
})

export class ProyectoService {
    constructor( private http: HttpService,
                 private cache: RequestCache) { }

    getAll(){
      return this.http.post(environment.API.CONTENT, 'proyectos/all', null, true);
    }

    find( query ){
      return this.http.post(environment.API.CONTENT, 'proyectos/all', query, true);
    }

    findOne( query ){
      return this.http.post(environment.API.CONTENT, 'proyectos/one', {query}, true);
    }

    save( data ) {
      return this.http.put(environment.API.CONTENT, 'proyectos/save', data, true);
    }

    update( id, data ) {
      return this.http.patch(environment.API.CONTENT, 'proyectos/update/' + id, data, true);
    }

    delete( id ){
      return this.http.delete(environment.API.CONTENT, 'proyectos/delete/' + id, true);
    }

    /**
     * Open routes
     */

    _getAll(){
      return this.http.post(environment.API.CONTENT, '_proyectos/all', null, false);
    }

    _find( query ){
      return this.http.post(environment.API.CONTENT, '_proyectos/all', query, false);
    }

    _distinct( field, query = null ){
      return this.http.post(environment.API.CONTENT, '_proyectos/distinct', {field, query}, false);
    }

    _findOne( query ){
      return this.http.post(environment.API.CONTENT, '_proyectos/one', {query}, false);
    }

    clearCache(){
      this.cache.clear('proyectos');
    }

}
