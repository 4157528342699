import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from './services/user.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean  {
        return new Promise((resolve, reject) => {
            const user = this.userService.user;
            if (user){
                return resolve(this.validateRole( route, user ));
            } else {
                this.userService.checkUser2().then( u => {
                    return resolve(this.validateRole( route, u ));
                }).catch((error) => {
                    console.error(error);
                    return reject(false);
                });
            }
        });
    }

    validateRole( route: ActivatedRouteSnapshot, user ){
        if (route.data.roles.indexOf(user.role) === -1){
            this.router.navigate(['/']);
            return false;
        } else {
            return true;
        }
    }
}
