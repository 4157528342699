import * as S3 from 'aws-sdk/clients/s3';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ResponseCode } from 'src/app/enum/response-code.enum';
import { PaginaService } from 'src/app/services/pagina.service';
import { Pagina } from 'src/app/interfaces/pagina';
import { UploadService } from 'src/app/services/upload.service';
import { S3Object } from 'src/app/interfaces/s3object';
import { environment } from 'src/environments/environment';
import { SeoService } from 'src/app/services/seo.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoAdminComponent implements OnInit {
  pagina: Pagina;
  errorMessage: any;
  loading = false;
  s3objects: S3Object[];
  filteredResults: S3Object[];
  imageSearchText: string;
  sortKey: string;
  sortOptions = [
    { label: 'A a Z', value: 'Key' },
    { label: 'Z a A', value: '!Key' },
    { label: 'Chico a grande', value: 'Size' },
    { label: 'Grade a chico', value: '!Size' },
    { label: 'Recientes a antiguas', value: 'LastModified' },
    { label: 'Antiguas a Recientes', value: '!LastModified' }
  ];

  @ViewChild('imagenUpload') imagenUpload: any;

  constructor(private uploadService: UploadService,
    private dataService: PaginaService,
    private userService: UserService,
    private seoService: SeoService) { }

  ngOnInit(): void {
    this.seoService.setTitle('Palma | Administrador');
    this.dataService.findOne({ _id: '5f77c9cd18eb196a50be8f9c' }).subscribe((res: any) => {
      const { content, messageCode } = res;

      if (messageCode && messageCode === ResponseCode.OK) {
        this.pagina = content;
      }
    }, (err) => {
      const { message, messageCode } = err.error;
      this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
    });

    const user = this.userService.user;

    if (!user) {
      console.error('Unatorized access.');
    }

    const bucket = new S3(
      {
        accessKeyId: 'AKIA' + user?.credentials?.b,
        secretAccessKey: user?.credentials?.a?.split('').reverse().join('')
      }
    );

    const params = {
      Bucket: 'palma-assets',
    };

    const onListObjects = (err, s3data) => {
      if (err) {
        console.error('Error', err);
      } else {
        this.s3objects = s3data.Contents;
      }
    };

    bucket.listObjects(params, onListObjects);
  }

  async save() {
    // Upload images
    if (this.imagenUpload._files.length) {
      this.loading = true;
      const file = this.imagenUpload._files[0];
      const promises = [];

      promises.push(
        this.uploadService.uploadFile(file, 'info').then((response: any) => {
          this.pagina.imagen = response.url;
          this.imagenUpload.clear();
        }, err => {
          console.error(err);
          this.loading = false;
        })
      );

      await Promise.all(promises).then(values => {
        this.imagenUpload.clear();
        this.loading = false;
      });
    }

    this.dataService.update(this.pagina._id, this.pagina).subscribe((res: any) => {
      console.log('Contenido actualizado!');
    }, (err) => {
      console.error(err);
      const { message, messageCode } = err.error;
      this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
    });
  }

  selectImage(image: S3Object) {
    this.pagina.imagen = this.uploadService.getVariant(image.Key, {});
  }

  search($event) {
    this.filteredResults = [];
    for (const s3object of this.s3objects) {
      if (s3object.Key.toLowerCase().indexOf($event.query.toLowerCase()) !== -1) {
        this.filteredResults.push(s3object);
      }
    }
  }

  searchDone($event) {
    this.selectImage($event);
  }

  onSortChange() {
    if (this.sortKey.indexOf('!') === 0) {
      this.sort(-1);
    }
    else {
      this.sort(1);
    }
  }

  sort(order: number): void {
    const s3objects = [...this.s3objects];
    const property = this.sortKey.replace('!', '');
    s3objects.sort((data1, data2) => {
      const value1 = data1[property];
      const value2 = data2[property];
      const result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      return (order * result);
    });

    this.s3objects = s3objects;
  }

  deleteImagen() {
    this.pagina.imagen = null;
  }
}
