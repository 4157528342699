import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyFilter',
  pure: false
})
export class PropertyFilterPipe implements PipeTransform {

  transform(items: any[], filter: any): any {
    if (!items || !filter ) {
        return items;
    }
    return items.filter(item => item[filter.property] === filter.value);
  }

}
