import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { RequestCache } from './request-cache.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TraduccionService {

  constructor(private http: HttpService,
              private cache: RequestCache) { }

  getAll(){
    return this.http.post(environment.API.CONTENT, 'traducciones/all', null, true);
  }

  find( query ){
    return this.http.post(environment.API.CONTENT, 'traducciones/all', query, true);
  }

  findOne( query ){
    return this.http.post(environment.API.CONTENT, 'traducciones/one', {query}, true);
  }

  save( data ) {
    return this.http.put(environment.API.CONTENT, 'traducciones/save', data, true);
  }

  update( id, data ) {
    return this.http.patch(environment.API.CONTENT, 'traducciones/update/' + id, data, true);
  }

  delete( id ){
    return this.http.delete(environment.API.CONTENT, 'traducciones/delete/' + id, true);
  }

  /**
   * Openn routs
   */

  _getAll(){
    return this.http.post(environment.API.CONTENT, '_traducciones/all', null, true);
  }

  _find( query ){
    return this.http.post(environment.API.CONTENT, '_traducciones/all', query, true);
  }

  _findOne( query ){
    return this.http.post(environment.API.CONTENT, '_traducciones/one', {query}, true);
  }

  clearCache(){
    this.cache.clear('traducciones');
  }
}
