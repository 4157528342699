import { Component, OnInit } from '@angular/core';
import { ResponseCode } from '../enum/response-code.enum';
import { Portada } from '../interfaces/portada';
import { PortadaService } from '../services/portada.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  data: Portada[];
  verticales: Portada[];
  horizontales: Portada[];
  portada: Portada;
  portadaVertical: Portada;
  errorMessage: any;


  constructor(  private dataService: PortadaService ) {}

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.dataService._find({query: {oculto: false}}).subscribe((res: any) => {
      const { content, messageCode } = res;
      if (messageCode && messageCode === ResponseCode.OK) {
        this.data = content;
        this.verticales = [] as unknown as Portada[];
        this.horizontales = [] as unknown as Portada[];
        this.data.forEach( (p, i) => {
          if (p.vertical) {
            this.verticales.push(p);
          } else {
            this.horizontales.push(p);
          }
        });
        const indiceHorizontal = Math.floor(Math.random() * this.horizontales.length);
        const indiceVertical = Math.floor(Math.random() * this.verticales.length);
        this.portada = this.horizontales[indiceHorizontal];
        this.portadaVertical = this.verticales[indiceVertical];
        // this.infoPortadas(indiceHorizontal, indiceVertical);
      }
    }, (err) => {
      const { message, messageCode } = err.error;
      this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
    });
  }

  infoPortadas(indiceHorizontal, indiceVertical){
    console.log('Potadas horisontales consideradas: ', this.horizontales);
    console.log('Portadas verticales consideradas: ', this.verticales);
    console.log('Numero de horisontales consideradas: ', this.horizontales.length);
    console.log('Numero de verticales consideradas: ', this.verticales.length);
    console.log('Horizontal sorteada: ', indiceHorizontal + 1);
    console.log('Vertical sorteada: ', indiceVertical + 1);
  }

}
