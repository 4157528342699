import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { environment } from '../../environments/environment';
import { ResponseCode } from '../enum/response-code.enum';
import { User } from 'aws-sdk/clients/appstream';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userDataReceived: Subject<any> = new Subject();
  public $userDataReceived;
  private loginStatusChanged: Subject<boolean> = new Subject<boolean>();
  public $loginStatusChanged;
  private languageChanges: Subject<any> = new Subject();
  public $languageChanges;
  public dataLoading = false;
  private userDetails: any;
  private countryList: any = [];
  private credits = new BehaviorSubject(0);

  constructor(public http: HttpService ) {
    this.$userDataReceived = this.userDataReceived.asObservable();
    this.$languageChanges = this.languageChanges.asObservable();
    this.$loginStatusChanged = this.loginStatusChanged.asObservable();
  }

  userCredits() {
    return this.credits;
  }

  public set user(value) {
    this.userDetails = value;
    this.userDataReceived.next(value);
  }


  public get user() {
    return this.userDetails;
  }

  statusChanged() {}

  public get countries(): any[] {
    return this.countryList;
  }

  public set countries(list) {
    this.countryList = list;
  }

  checkUser() {
    this.dataLoading = true;
    this.http.get(environment.API.AUTH, 'users/check_me', true).subscribe((res: any) => {
      const { content, messageCode } = res;
      if (messageCode && messageCode === ResponseCode.OK) {
        this.user = content.user;
        this.loginStatusChanged.next(true);
      }
    }, () => {
      this.loginStatusChanged.next(false);
      localStorage.removeItem('token');
      this.dataLoading = false;
    });
  }

  async checkUser2() {
    const res = await this.http.get(environment.API.AUTH, 'users/check_me', true).toPromise() as any;
    const { content, messageCode } = res;
    if (messageCode && messageCode === ResponseCode.OK) {
      this.user = content.user;
      this.loginStatusChanged.next(true);
      return res.content.user;
    } else {
      this.loginStatusChanged.next(false);
      localStorage.removeItem('token');
      return false;
    }
  }

  public changeLang(code) {
    this.languageChanges.next(code);
  }
}
