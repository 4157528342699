import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

const maxAge = 30000;
@Injectable()
export class RequestCache {

  cache = new Map();

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    let url = req.urlWithParams;
    if (url.includes('_paginas/one')){
      url += req.body.query._id;
    } else if (url.includes('_traducciones/all')){
      url += req.body.query.origen;
    }
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    const isExpired = cached.lastRead < (Date.now() - maxAge);
    const expired = isExpired ? 'expired ' : '';
    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    let url = req.url;
    if (url.includes('_paginas/one')){
      url += req.body.query._id;
    } else if (url.includes('_traducciones/all')){
      url += req.body.query.origen;
    }
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    const expired = Date.now() - maxAge;
    this.cache.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }

  clear(url){
    const keys =  this.cache.keys();
    for (const key of keys) {
      if (key.includes(url)){
        this.cache.set(key, undefined);
      }
    }
  }
}
