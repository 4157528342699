
<div class="grid-x grid-magin-x ">
    <div *ngFor="let item of data"  class="noticia cell">
        <strong class="noticia__titulo">{{item.titulo}}</strong>
        <div class="noticia__contenido app__quill-content" [innerHTML]="item.contenido"></div>
        <div *ngIf="item.fotos.length" class="noticia__imagenes grid-x grid-margin-y">
            <div class="cell" *ngFor="let foto of item.fotos">
                <img  class="noticia__imagen" [src]="foto" [alt]="item.titulo">
            </div>
        </div>
    </div>
</div>
