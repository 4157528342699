<div class="app__sidcontent off-canvas position-right cell-block-container open-close-container" id="offCanvas" data-off-canvas>
  <div class="grid-x grid-padding-x" [@openClose]="isOpen ? 'open' : 'closed'" >
    <div class="cell auto cell-block-y" id="offCanvas_scrollable" class="cell auto app__sidcontent-scrollable">
      <router-outlet name="mas"></router-outlet>
    </div>
    <div class="cell shrink">
      <button class="close-button" type="button" data-close>
        <i class="pi pi-times"></i>
      </button>
      <button class="up-button" type="button" (click)="scrollTop()" [ngClass]="{'hide': contacto}" >
        <i class="pi pi-arrow-up"></i>
      </button>
    </div>
  </div>
</div>
<div class="app__logo-container show-for-medium">
  <a class="app__logo" routerLink=""><img [src]="'/assets/images/logos/'+logo" alt=""></a>
</div>
<div class="app__main off-canvas-content grid-x grid-padding-x" data-off-canvas-content>
  <div class="cell auto">
    <div class="grid-y grid-margin-y grid-frame">
      <a class="cell shrink" [routerLink]="[{ outlets: { mass: null, primary: null}}]">
        <div class="app__logo-container app__logo-container--inner">
          <a class="app__logo show-for-small-only" [routerLink]="[{ outlets: { mass: null, primary: null}}]"><img [src]="'/assets/images/logos/'+logo" alt=""></a>
        </div>
      </a>
      <div class="cell auto">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="main-menu cell shrink text-center">
    <ul *ngIf="!admin"  class="main-menu__links menu">
      <li><a [routerLink]="[{ outlets: { mas: ['contacto']}}]" class="main-menu__link" data-open="offCanvas">{{'Contacto' | translate}}</a></li>
      <li><a [routerLink]="[{ outlets: { mas: ['noticias']}}]" class="main-menu__link" data-open="offCanvas">{{'Noticias' | translate}}</a></li>
      <li><a [routerLink]="[{ outlets: { mas: ['info']}}]" class="main-menu__link active" data-open="offCanvas">{{'Info' | translate}}</a></li> 
      <li><a [routerLink]="[{ outlets: { mass: null, primary: ['proyectos']}}]" class="main-menu__link" data-close="offCanvas">{{'Proyectos' | translate}}</a></li>
    </ul>
    <ul *ngIf="admin" class="main-menu__links menu">
      <li><a routerLink="admin/traducciones" class="main-menu__link" data-close="offCanvas">Traducciones</a></li>
      <li><a routerLink="admin/portadas" class="main-menu__link" data-close="offCanvas">Portadas</a></li>
      <li><a routerLink="admin/contacto" class="main-menu__link" data-close="offCanvas">Contacto</a></li>
      <li><a routerLink="admin/noticias" class="main-menu__link" data-close="offCanvas">Noticias</a></li>
      <li><a routerLink="admin/info" class="main-menu__link" data-close="offCanvas">Info</a></li> 
      <li><a routerLink="admin/proyectos" class="main-menu__link" data-close="offCanvas">Proyectos</a></li>
    </ul>
    <div class="main-menu__bottom-links">
      <a class="main-menu__link main-menu__link--instagram" href="https://www.instagram.com/palmamx/" target="_blank">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-instagram fa-w-14 fa-3x">
        <path fill="currentColor"
            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
            class=""></path>
        </svg></a>
        <a *ngIf="translate.currentLang !== 'en'" (click)="switchLang('en')"  class="main-menu__link"  >EN</a>
        <a *ngIf="translate.currentLang !== 'es'" (click)="switchLang('es')"  class="main-menu__link"  >ES</a>
    </div>
  </div>
</div>
<div *ngIf="!admin" id="app__rupestre-container" class="app__rupestre-container" [ngClass]="{'hide': gifTimer > 0}">
  <img class="app__rupestre" src="/assets/images/PALMA-Animacion_RUPESTRE.gif" alt="">
</div>
<div class="app__debug" [ngClass]="{'hide': true}">
  <h6>Debug:</h6>
  {{debug | json}}
</div>