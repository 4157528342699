import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Campo } from 'src/app/interfaces/campo';
import { Noticia } from 'src/app/interfaces/noticia';
import { Pagina } from 'src/app/interfaces/pagina';
import { Proyecto } from 'src/app/interfaces/proyecto';
import { Traduccion } from 'src/app/interfaces/traduccion';
import { NoticiasService } from 'src/app/services/noticia.service';
import { PaginaService } from 'src/app/services/pagina.service';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { TraduccionService } from 'src/app/services/traduccion.service';
import { FilterUtils } from 'primeng/utils';
import { ResponseCode } from 'src/app/enum/response-code.enum';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-traducciones',
  templateUrl: './traducciones.component.html',
  styleUrls: ['./traducciones.component.scss']
})
export class TraduccionesComponent implements OnInit {
  items = [];
  filteredItems: any;
  filtros: SelectItem[];
  filtro = 'faltante';
  campos = [] as Campo[];
  traducciones = [] as Traduccion[];
  loading = true;

  constructor(
              private traduccionesService: TraduccionService,
              private paginaService: PaginaService,
              private noticiasService: NoticiasService,
              private proyectoService: ProyectoService,
              private seoService: SeoService
    ) { }

  ngOnInit(): void {
    this.seoService.setTitle('Palma | Administrador');
    this.filtros = [
      {label: 'Faltantes', value: 'faltante'},
      {label: 'Por revisar', value: 'revisar'},
      {label: 'Revisadas', value: 'revisada'},
    ];
    this.getItems();
  }

  save(item){
    if (typeof item.traduccion._id === 'undefined') {
      this.traduccionesService.save(item.traduccion).subscribe((res: any) => {
        const { content, messageCode } = res;
        if (messageCode && messageCode === ResponseCode.OK) {
          item.status = 'revisada';
          this.filterChange();
        }
      }, (err) => {
        console.error(err);
      });
    } else {
      // Update
      this.traduccionesService.update(item.traduccion._id, item.traduccion).subscribe((res: any) => {
        item.status = 'revisada';
        this.filterChange();
      }, (err) => {
        console.error(err);
      });
    }
  }

  filterChange(){
    this.filteredItems = this.items;
    this.filteredItems = this.filteredItems.filter(item => item.status === this.filtro);
  }

  async getItems(){
    Promise.all( [ this.getTraducciones(), this.getNoticias(), this.getPaginas(), this.getProyectos()])
    .then( responses => {
       this.traducciones = responses[0];
       this.campos = this.campos.concat(responses[1], responses[2], responses[3]);

       this.campos.forEach( campo => {
         let traduccion = this.traducciones.find(x => (x.origen === campo.origen && x.campo === campo.nombre));

         let status: string;
         if ( typeof traduccion === 'undefined'){
            console.log(campo);
            status = 'faltante';
            traduccion =  {
              origen: campo.origen,
              campo: campo.nombre,
              contenido: ''
            };
         } else if (traduccion.updated_at > campo.fecha) {
            status = 'revisada';
         } else {
            status = 'revisar';
         }

         const item = {campo, traduccion, status};
         this.items.push(item);
       });

       this.filterChange();
       this.loading = false;
     });
  }

  async getTraducciones() {
    const res = await this.traduccionesService.getAll().toPromise() as any;
    const data = res.content as Traduccion[];
    return data;
  }

  async getNoticias() {
    const res = await this.noticiasService.getAll().toPromise() as any;
    let data = [];
    const tempData = res.content as Noticia[];
    data = data.concat( tempData.map(
      item => ({origen: item._id, nombre: 'titulo', contenido: item.titulo, html: false, fecha: item.updated_at})
    ));
    data = data.concat(tempData.map(
      item => ({origen: item._id, nombre: 'contenido', contenido: item.contenido, html: true, fecha: item.updated_at})
    ));
    return data;
  }

  async getPaginas() {
    const res = await this.paginaService.getAll().toPromise() as any;
    let data = [];
    const tempData = res.content as Pagina[];
    data = data.concat( tempData.map(
        item => ({origen: item._id, nombre: 'contenido', contenido: item.contenido, html: true, fecha: item.updated_at})
    ));
    return data;
  }

  async getProyectos() {
    const res = await this.proyectoService.getAll().toPromise() as any;
    let data = [];
    const tempData = res.content as Proyecto[];
    data = data.concat( tempData.map(
        item => ({origen: item._id, nombre: 'titulo', contenido: item.titulo, html: false, fecha: item.updated_at})
    ));
    data = data.concat( tempData.map(
        item => ({origen: item._id, nombre: 'descripcion', contenido: item.descripcion, html: true, fecha: item.updated_at})
    ));
    data = data.concat( tempData.map(
        item => ({origen: item._id, nombre: 'info', contenido: item.info, html: true, fecha: item.updated_at})
    ));
    return data;
  }
}
