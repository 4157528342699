

<div class="grid-x grid-magin-x grid-margin-y">
     <div class="cell">
        <div class="app__quill-content" [innerHTML]="pagina?.contenido"></div>
    </div>
</div>
<div class="app__creditos">
    {{'Identidad gráfica y diseño web por María Marín de Buen y Proyectos ningúem.' | translate}} <br>
    {{'Programación web por Propuesta Digital.' | translate}} <br>
</div>
