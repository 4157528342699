  <div class="grid-container padding-top-40-80">
      <div class="grid-x grid-margin-x grid-margin-y">
          <div class="cell medium-6">
                <div class="squares-ornament show-for-medium">
                    <div class="squares-ornament__first_div"></div>
                    <div class="squares-ornament__second_div"></div>
                    <div class="squares-ornament__third_div"></div>
                </div>
          </div>

          <div class="cell medium-5">
              <form [formGroup]="loginForm" (ngSubmit)="login()">
                  <div class="grid-x grid-margin-x">
                      <div class="cell">
                          <h5>Iniciar Sesión</h5>
                      </div>
                      <div class="cell">
                          <input type="text" formControlName="username" class="form-control nofocus"
                              placeholder="User name/Email"
                              [ngClass]="{ 'is-invalid': submitted && logincontrol.username.errors }">
                          <div *ngIf="submitted && logincontrol.username.errors" class="invalid-feedback">
                              <div *ngIf="logincontrol.username.errors.required">El usuario o Correo es
                                  obligatorio
                              </div>
                          </div>
                      </div>
                      <div class="cell">
                          <input type="password" formControlName="password" class="form-control nofocus"
                              placeholder="Password"
                              [ngClass]="{'is-invalid': submitted && logincontrol.password.errors}">
                          <div *ngIf="submitted && logincontrol.password.errors" class="invalid-feedback">
                              <div *ngIf="logincontrol.password.errors.minlength">
                                  La contraseña debe ser al menos de 6 caracteres
                              </div>
                              <div *ngIf="logincontrol.password.errors.required">
                                  La contraseña esobligatoria
                              </div>
                          </div>
                      </div>
                      <div class="">
                          {{errorMessage}}
                      </div>

                      <div class="cell">
                          <p (click)="forgetpassword()" class="curser text-dark">¿Olvidaste tu contraseña?</p>
                      </div>

                      <div class="cell shrink">
                          <button type="submit" class="button sign_in">Iniciar sesion
                              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i></button>
                      </div>

                      <!-- <div class="mb-3 mt-4">
                        <span class="text-info fnt_14">Or you may join using ...</span>
                        </div>
                        <div>
                        <span> <button type="button" (click)="signInWithFB()" class="btn btn-info px_4 px_pad ">Login with
                            Facebook</button></span>
                        <span> <button type="button" (click)="signInWithGoogle()" class="btn btn-info px_4 ml_4 px_pad ">Login
                        with Google</button></span></div> -->
                  </div>
              </form>
          </div>
      </div>
  </div>