import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class PaginaService {
    constructor( private http: HttpService) { }
    getAll(){
      return this.http.post(environment.API.CONTENT, 'paginas/all', null, true);
    }

    find( query ){
      return this.http.post(environment.API.CONTENT, 'paginas/all', query, true);
    }

    findOne( query ){
      return this.http.post(environment.API.CONTENT, 'paginas/one', {query}, true);
    }

    update( id, data ) {
      return this.http.patch(environment.API.CONTENT, 'paginas/update/' + id, data, true);
    }

    /**
     * Open routes
     */

    _findOne( query ){
      return this.http.post(environment.API.CONTENT, '_paginas/one', {query}, false);
    }
}
