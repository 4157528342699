import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from '../../../environments/environment';
import { ResponseCode } from '../../enum/response-code.enum';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm: FormGroup;
  submitted = false;
  loading = false;
  usernameValid: boolean;
  errorMessage: any;
  errorMessageUsername: any;
  constructor(private formBuilder: FormBuilder,
              private http: HttpService,
              public userService: UserService,
              private router: Router) { }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });

  }
  get signupControl() { return this.signupForm.controls; }

  async checkUsername() {
    if (this.signupForm.value.firstname.trim() !== '') {
      const res = await this.http
        .post(environment.API.AUTH, 'auth/check_username/' + this.signupForm.value.firstname, {}, false)
        .toPromise() as any;
      const { content, messageCode } = res;
      if (messageCode === ResponseCode.OK) {
        this.usernameValid = content.valid;
        if (this.usernameValid === false) {
          this.errorMessageUsername = 'Ese usuario ya está en uso';
        } else {
          this.errorMessageUsername = '';
        }
        return content.valid;
      }
    }
  }

  signUp() {
    this.submitted = true;
    this.checkUsername().then( res => {
        if (this.signupForm.valid && this.usernameValid) {
          const formValue = this.signupForm.value;
          formValue.firstname = formValue.firstname.trim();
          formValue.username = formValue.firstname.trim();
          formValue.email = formValue.firstname.trim();
          formValue.password = formValue.password.trim();
          if (formValue.firstname !== '' && formValue.password !== '') {
            this.loading = true;
            this.http.post(environment.API.AUTH, 'auth/signup', formValue, false).subscribe((res2: any) => {
              const { content, messageCode } = res2;
              if (messageCode && messageCode === ResponseCode.CREATED) {
                this.handleSignupSuccess();
                this.loading = false;
              }
            }, (err) => {
              this.loading = false;
              const { message, messageCode } = err.error;
              this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Something went wrong...' : message;
            });
          } else {
            this.errorMessage = 'Please enter proper name and password';
          }
        }
    });
  }

  handleSignupSuccess() {
    this.router.navigate(['/admin']).then(() => {
      this.signupForm.reset();
    });
  }
}
