import { AuthGuard } from './auth-guard';
import { ContactoAdminComponent } from './admin/contacto/contacto.component';
import { ContactoComponent } from './sidebar/contacto/contacto.component';
import { HomeComponent } from './home/home.component';
import { InfoAdminComponent } from './admin/info/info.component';
import { InfoComponent } from './sidebar/info/info.component';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { NgModule } from '@angular/core';
import { NoticiasAdminComponent } from './admin/noticias/noticias.component';
import { NoticiasComponent } from './sidebar/noticias/noticias.component';
import { PortadasComponent } from './admin/portadas/portadas.component';
import { ProyectoComponent } from './proyecto/proyecto.component';
import { ProyectosAdminComponent } from './admin/proyectos/proyoectos.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { Role } from './enum/role';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from './auth/signup/signup.component';
import { TraduccionesComponent } from './admin/traducciones/traducciones.component';


const routes: Routes = [
  { path: 'proyectos', component: ProyectosComponent },
  { path: 'proyectos/:slug', component: ProyectoComponent },
  { path: 'home', component: HomeComponent },
  { path: 'info', outlet: 'mas', component: InfoComponent},
  { path: 'noticias', outlet: 'mas', component: NoticiasComponent},
  { path: 'contacto', outlet: 'mas', component: ContactoComponent},
  { path: 'login', redirectTo: 'iniciar-sesion', pathMatch: 'full'},
  { path: 'iniciar-sesion', component: LoginComponent },
  { path: 'logout', redirectTo: 'cerrar-sesion', pathMatch: 'full'},
  { path: 'cerrar-sesion', component: LogoutComponent },
  { path: 'signup', redirectTo: 'registro', pathMatch: 'full'},
  { path: 'registro', component: SignupComponent },
  {
    path: 'admin/traducciones',
    component: TraduccionesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'admin/portadas',
    component: PortadasComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'admin/noticias',
    component: NoticiasAdminComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'admin/info',
    component: InfoAdminComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'admin/proyectos',
    component: ProyectosAdminComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'admin/contacto',
    component: ContactoAdminComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: '', component: HomeComponent },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
