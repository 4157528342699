import * as S3 from 'aws-sdk/clients/s3';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Noticia } from 'src/app/interfaces/noticia';
import { NoticiasService } from 'src/app/services/noticia.service';
import { ResponseCode } from 'src/app/enum/response-code.enum';
import { S3Object } from 'src/app/interfaces/s3object';
import { SelectItem } from 'primeng/api';
import { SeoService } from 'src/app/services/seo.service';
import { UploadService } from 'src/app/services/upload.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss']
})
export class NoticiasAdminComponent implements OnInit {
  cols: any[];
  data: Noticia[];
  displayDialog: boolean;
  errorMessage: any;
  exportColumns: any[];
  filteredResults: S3Object[];
  imageSearchText: string;
  item: Noticia;
  loading = false;
  newNoticia: boolean;
  nuevoIngrediente: string;
  portada: any;
  contenidoReadonly = true;
  s3objects: S3Object[];
  selectedData: Noticia;
  sortKey: string;
  sortOptions: SelectItem[];
  es = {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Borrar'
  };

  @ViewChild('fotosUpload') fotosUpload: any;

  constructor(public userService: UserService,
    private seoService: SeoService,
    private uploadService: UploadService,
    private router: Router,
    private dataService: NoticiasService) { }

  ngOnInit(): void {
    this.seoService.setTitle('Palma | Administrador');
    this.getAll();

    this.cols = [
      { field: 'titulo', header: 'Titulo' },
      { field: 'created_at', header: 'Fecha' },
      { field: 'oculto', header: 'Oculta' },
      { field: 'slug', header: 'Controles' },
    ];

    this.sortOptions = [
      { label: 'A a Z', value: 'Key' },
      { label: 'Z a A', value: '!Key' },
      { label: 'Chico a grande', value: 'Size' },
      { label: 'Grade a chico', value: '!Size' },
      { label: 'Recientes a antiguas', value: 'LastModified' },
      { label: 'Antiguas a Recientes', value: '!LastModified' }
    ];

    const user = this.userService.user;

    if (!user) {
      console.error('Unatorized access.');
    }

    const bucket = new S3(
      {
        accessKeyId: 'AKIA' + user?.credentials?.b,
        secretAccessKey: user?.credentials?.a?.split('').reverse().join('')
      }
    );
    const params = {
      Bucket: 'palma-assets',
    };

    const onListObjects = (err, s3data) => {
      if (err) {
        console.error('Error', err);
      } else {
        this.s3objects = s3data.Contents;
      }
    };

    bucket.listObjects(params, onListObjects);
  }

  getAll() {
    this.dataService.find({ query: { sortBy: 'sortDate', asc: false } }).subscribe((res: any) => {
      const { content, messageCode } = res;
      if (messageCode && messageCode === ResponseCode.OK) {
        this.data = content;
      }
    }, (err) => {
      const { message, messageCode } = err.error;
      this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
    });
  }

  goTo(slug) {
    this.router.navigate(['noticias/' + slug]);
  }


  // Edit form
  async save() {
    const data = [...this.data];

    // Upload images
    if (this.fotosUpload._files.length) {
      this.loading = true;
      const files = this.fotosUpload._files;
      const promises = [];

      files.forEach(foto => {
        promises.push(
          this.uploadService.uploadFile(foto, 'noticia').then((response: any) => {
            this.item.fotos.push(response.url);
          }, err => {
            console.error(err);
            this.loading = false;
          }));
      });

      await Promise.all(promises).then(values => {
        this.fotosUpload.clear();
        this.loading = false;
      });
    }

    // Save New
    if (this.newNoticia) {
      this.dataService.save(this.item).subscribe((res: any) => {
        const {
          content,
          messageCode
        } = res;
        if (messageCode && messageCode === ResponseCode.OK) {
          data.push(content.content);
          this.data = data;
          this.item = null;
          this.displayDialog = false;
        }
      }, (err) => {
        const {
          message,
          messageCode
        } = err.error;
        this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
      });
    } else {

      // Update
      this.dataService.update(this.item._id, this.item).subscribe((res: any) => {
        data[this.data.indexOf(this.selectedData)] = this.item;
        this.data = data;
      }, (err) => {
        this.getAll();
        const {
          message,
          messageCode
        } = err.error;
        this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
      });

      this.displayDialog = false;
    }
  }

  deleteById(id) {
    this.dataService.delete(id).subscribe((res: any) => {
      this.data = this.data.filter((item) => item._id !== id);
    }, (err) => {
      this.data = this.data.filter((item) => item._id !== id);
      const {
        message,
        messageCode
      } = err.error;
      this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
    });
  }

  delete() {
    this.deleteById(this.item._id);
    this.displayDialog = false;
  }

  showDialogToAdd() {
    this.newNoticia = true;
    this.item = {
      portada: { original: '/assets/images/icons/noticias.svg', thumbnail: '/assets/images/icons/noticias.svg' },
      ingredientes: [],
      fotos: []
    } as unknown as Noticia;
    this.displayDialog = true;
  }

  onRowSelect(event) {
    this.newNoticia = false;
    this.item = this.cloneNoticia(event.data);
    this.displayDialog = true;

    if (!isNaN(Date.parse(this.item.created_at as any))) {
      this.item.sortDate = new Date(event.data.sortDate);
    } else {
      this.item.sortDate = null;
    }
  }

  cloneNoticia(c: Noticia): Noticia {
    const noticia = {} as Noticia;

    for (const prop in c) {
      if (c.hasOwnProperty(prop)) {
        noticia[prop] = c[prop];
      }
    }

    return noticia;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  // Prevent editor autofocus
  detalleOnShow() {
    this.contenidoReadonly = false;
  }

  // Image management
  onUpload(event) {
    for (const file of event.files) {
      this.uploadService.uploadFile(file, 'crud-test').then((data: any) => {
        const original = data.url;
        const thumbnail = this.uploadService.getVariant(data.key, { resize: 'cover', height: 70, width: 100 });
      }, err => {
        console.error(err);
      });
    }

    this.fotosUpload.clear();
  }

  search($event) {
    this.filteredResults = [];
    for (const s3object of this.s3objects) {
      if (s3object.Key.toLowerCase().indexOf($event.query.toLowerCase()) !== -1) {
        this.filteredResults.push(s3object);
      }
    }
  }

  searchDone($event) {
    this.selectImage($event);
  }

  searchDone2($event) {
    this.selectImage2($event);
  }

  selectImage(image: S3Object) {
    const original = this.uploadService.getVariant(image.Key, {});
    const thumbnail = this.uploadService.getVariant(image.Key, { resize: 'cover', height: 70, width: 100 });
  }

  selectImage2(image: S3Object) {
    this.item.fotos.push(this.uploadService.getVariant(image.Key, {}));
  }

  onSortChange() {
    if (this.sortKey.indexOf('!') === 0) {
      this.sort(-1);
    }
    else {
      this.sort(1);
    }
  }

  sort(order: number): void {
    const s3objects = [...this.s3objects];
    const property = this.sortKey.replace('!', '');
    s3objects.sort((data1, data2) => {
      const value1 = data1[property];
      const value2 = data2[property];
      const result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      return (order * result);
    });

    this.s3objects = s3objects;
  }

  deleteFoto(i) {
    this.item.fotos.splice(i, 1);
  }
}
