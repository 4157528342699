  <div class="grid-x grid-padding-y app__scrollable">
    <div class="cell">
      <p-table #dt [columns]="cols" [value]="data" selectionMode="single" [(selection)]="selectedData"
        (onRowSelect)="onRowSelect($event)" [paginator]="true" [rows]="10" [autoLayout]="true">
        <ng-template pTemplate="caption">
          Noticias
          <p-button class="float-right" icon="pi pi-refresh" (click)="getAll()"></p-button>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon *ngIf="col.field == 'titulo'" [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr [pSelectableRow]="data">
            <td>{{data.titulo}}</td>
            <td>
              <strong>{{data.sortDate |  date:'d/M/yy, h:mm a' }}</strong>
            </td>
            <td>
              <i *ngIf="data.oculto"  class="pi pi-check" style="font-size: 2rem"></i>
              <i *ngIf="!data.oculto"  class="pi pi-times" style="font-size: 2rem"></i>
            </td>
            <td width="100px">
              <button type="button" pButton icon="pi pi-external-link" (click)="goTo(data.slug)"></button>&nbsp;
              <button type="button" pButton icon="pi pi-times" (click)="deleteById(data._id)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary" let-rowData>
          <div style="text-align:left">
            {{data ? data.length : 0 }} Noticias en total.
            <button class="float-right" type="button" pButton icon="pi pi-plus" (click)="showDialogToAdd()"
              label="&nbsp; Nueva"></button>
          </div>
        </ng-template>
      </p-table>

      <p-blockUI [blocked]="loading">
        <i class="pi pi-spin pi-spinner loading-spinner"></i>
      </p-blockUI>
      <p-dialog header="Detalle" [(visible)]="displayDialog" [responsive]="true" showEffect="fade" [modal]="true"
        [style]="{width: '80vw'}" (onShow)="detalleOnShow()">
        <form class="grid-x grid-margin-y grid-padding-x ui-fluid" *ngIf="item">
          <div class="cell small-10">
            <label for="titulo">Titulo
              <input pInputText id="titulo" name="titulo" [(ngModel)]="item.titulo" />
            </label>
          </div>
          <div class="cell small-2">
              <label for="descripcion">Oculta</label>
              <p-inputSwitch name="oculto" [(ngModel)]="item.oculto"></p-inputSwitch>
          </div>
          <!-- <div class="cell">
            <label for="slug">Slug
              <input pInputText id="slug" name="slug" [(ngModel)]="item.slug" />
            </label>
          </div>             -->
          <div class="cell">
            <label for="contenido">Contenido</label>
            <p-editor id="contenido" name="contenido" [(ngModel)]="item.contenido"
              [readonly]="contenidoReadonly" [style]="{'height':'200px'}">
            </p-editor>
          </div>
          <div class="cell small-12">
            <p-fileUpload #fotosUpload name="fotosUpload" customUpload="true" maxFileSize="5000000" fileLimit="20" multiple="multiple"
              accept="image/*" chooseLabel="&nbsp;Subir" cancelLabel="&nbsp;Cancelar" [showUploadButton]="false">
            </p-fileUpload>
          </div>
          <ng-container *ngFor="let foto of item.fotos; let i = index" >
            <div class="cell shrink">
              <img [src]="foto" [alt]="item.titulo" style="height: 110px;"/>
            </div>
            <div class="cell shrink">
              <button type="button" pButton icon="pi pi-times" (click)="deleteFoto(i)"></button>
            </div>
            </ng-container>
          <div class="cell">
            <p-panel header="Elegir imagenes de galeria de medios" [toggleable]="true" [collapsed]="true">
              <p-virtualScroller #virtualScroller [value]="s3objects" scrollHeight="50vh" [lazy]="true"  [rows]="5" [itemSize]="120" >
                <p-header>
                  <div class="sort-container">
                    <div class="grid-x grid-padding-x">
                      <div class="cell medium-8">
                        <p-autoComplete name="imageSearch" [(ngModel)]="imageSearchText" [suggestions]="filteredResults" field="Key"
                          (completeMethod)="search($event)" (onSelect)="searchDone2($event)" placeholder="Buscar por nombre"></p-autoComplete>
                      </div>
                      <div class="cell medium-4">
                        <p-dropdown name="dropdown1SortBy" [options]="sortOptions" [(ngModel)]="sortKey"
                          placeholder="Ordenar" (onChange)="onSortChange()" [style]="{'min-width':'140px'}"></p-dropdown>
                      </div>
                    </div>
                  </div>
                </p-header>
                <ng-template let-s3object pTemplate="item" let-i="index">
                  <div class="grid-x grid-padding-x align-middle">
                    <div class="cell medium-3">
                      <img class="gallery-pick__image" src="https://palma-assets.s3.amazonaws.com/{{s3object.Key}}">
                    </div>
                    <div class="cell medium-6" style="overflow: hidden; text-overflow: ellipsis;">
                      <strong>{{s3object.Key}}</strong>
                    </div>
                    <div class="cell auto">
                      <strong>{{s3object.LastModified |  date:'d/M/yy, h:mm a' }}</strong>
                    </div>
                    <div class="cell shrink">
                      <button type="button" pButton icon="pi pi-check" (click)="selectImage2(s3object)"></button>&nbsp;
                    </div>
                  </div>
                </ng-template>
                <ng-template let-car pTemplate="loadingItem">
                  <div class="car-details">
                    <div>
                      <div class="car-item-image empty-car-item-image"></div>
                      <div>
                        <b class="empty-car-item-text"></b>
                        <div class="empty-car-item-text"></div>
                      </div>
                    </div>
                    <div class="empty-car-item-button"></div>
                  </div>
                </ng-template>
              </p-virtualScroller>
            </p-panel>
          </div>
          <div  class="cell small-4">
            <label for="descripcion">Fecha de publicación</label>
            <p-calendar name="sortDate" [(ngModel)]="item.sortDate" [locale]="es" 
              dateFormat="dd/mm/yy" inputId="spanish" showTime="true" [readonlyInput]="true"></p-calendar>
          </div>
        </form>
        <p-footer>
          <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton icon="pi pi-times" (click)="delete()" label="&nbsp; Eliminar receta"
              class="float-left"></button>
            <button type="button" pButton icon="pi pi-ban" (click)="displayDialog = false"
              label="&nbsp; Cancelar"></button>
            <button type="button" pButton icon="pi pi-check" (click)="save()" label="&nbsp; Guardar"></button>
          </div>
        </p-footer>
      </p-dialog>
    </div>
  </div>