import { Component, OnInit } from '@angular/core';
import { ResponseCode } from 'src/app/enum/response-code.enum';
import { PaginaService } from 'src/app/services/pagina.service';
import { Pagina } from 'src/app/interfaces/pagina';
import { SeoService } from 'src/app/services/seo.service';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoAdminComponent implements OnInit {
  pagina: Pagina;
  errorMessage: any;

  constructor( private dataService: PaginaService,
               private seoService: SeoService) { }

  ngOnInit(): void {
    this.seoService.setTitle('Palma | Administrador');
    this.dataService.findOne({_id: '5f77bac176916669e41265bc'}).subscribe((res: any) => {
      const { content, messageCode } = res;

      if (messageCode && messageCode === ResponseCode.OK) {
        this.pagina = content;
      }
    }, (err) => {
      const { message, messageCode } = err.error;
      this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
    });
  }

  save(){
    this.dataService.update(this.pagina._id, this.pagina).subscribe((res: any) => {
      console.log('Contenido actualizado!');
    }, (err) => {
      console.error(err);
      const { message, messageCode } = err.error;
      this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
    });
  }
}
