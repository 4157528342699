import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from '../../environments/environment';
import { RequestCache } from './request-cache.service';



@Injectable({
  providedIn: 'root'
})

export class NoticiasService {
    constructor( private http: HttpService,
                 private cache: RequestCache) { }

    getAll(){
      return this.http.post(environment.API.CONTENT, 'noticias/all', null, true);
    }

    find( query ){
      return this.http.post(environment.API.CONTENT, 'noticias/all', query, true);
    }

    findOne( query ){
      return this.http.post(environment.API.CONTENT, 'noticias/one', {query}, true);
    }

    save( data ) {
      return this.http.put(environment.API.CONTENT, 'noticias/save', data, true);
    }

    update( id, data ) {
      return this.http.patch(environment.API.CONTENT, 'noticias/update/' + id, data, true);
    }

    delete( id ){
      return this.http.delete(environment.API.CONTENT, 'noticias/delete/' + id, true);
    }

    /**
     * Open routes
     */

    _getAll(){
      return this.http.post(environment.API.CONTENT, '_noticias/all', null, false);
    }

    _find( query ){
      return this.http.post(environment.API.CONTENT, '_noticias/all', query, false);
    }

    _findOne( query ){
      return this.http.post(environment.API.CONTENT, '_noticias/one', {query}, false);
    }

    clearCache(){
      this.cache.clear('noticias');
    }

}
