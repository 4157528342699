import { ActivatedRoute } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { Proyecto } from '../interfaces/proyecto';
import { ProyectoService } from '../services/proyecto.service';
import { ResponseCode } from '../enum/response-code.enum';
import { Subscription } from 'rxjs';
import { TranslateDataService } from '../services/translate-data.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
@Component({
  selector: 'app-proyecto',
  templateUrl: './proyecto.component.html',
  styleUrls: ['./proyecto.component.scss'],
  animations: [
    trigger('fullImageContainer', [
      state('open', style({
        opacity: 1,
        'max-height':  '100%',
      })),
      state('closed', style({
        opacity: 0,
        height: 0,
      })),
      transition('* => closed', [
        animate('1s', keyframes([
          style({ opacity: '1', offset: 0}),
          style({ opacity: '1', offset: 0.25}),
          style({ opacity: '0', offset: 1.0})
        ]))
      ]),
      transition('* => open', [
        animate('0s')
      ]),
    ]),
  ],
})
export class ProyectoComponent implements OnInit {
  sub: Subscription = new Subscription();
  slug: string;
  proyecto: Proyecto;
  proyectoEs: Proyecto;
  proyectoEn: Proyecto;
  errorMessage: any;
  fullImageContainer = false;
  fullImageSrc: string;
  scrollPosition = 0;

  constructor(  private dataService: ProyectoService,
                private dataTranslator: TranslateDataService,
                private translate: TranslateService,
                private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.setPageData();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => this.changeLang(event.lang));
  }

  async getProyecto(){
    if (typeof history.state.data === 'undefined'){
      await this.route.paramMap.pipe(first()).toPromise().then( param => this.slug = param.get('slug'));
      return await this.dataService._findOne({slug: this.slug}).toPromise().then( (res: any) => {
        const { content, messageCode } = res;
        if (messageCode && messageCode === ResponseCode.OK) {
          return content;
        }
      }).catch( err => {
        const { message, messageCode } = err.error;
        this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
        throw Error(err);
      });
    } else {
      return  history.state.data;
    }
  }

  async setPageData() {
    this.proyectoEs = await this.getProyecto();
    if (this.translate.currentLang === 'en'){
      this.changeLang('en');
    } else {
      this.proyecto = Object.assign({}, this.proyectoEs);
    }
  }

  changeLang(lang){
    if (lang === 'en'){
      if (typeof this.proyectoEn !== 'undefined'){
        this.proyecto = Object.assign({}, this.proyectoEn);
      } else {
        this.proyectoEn = Object.assign({}, this.proyectoEs);
        this.dataTranslator.translateItem(this.proyectoEn).then(() =>
          this.proyecto = Object.assign({}, this.proyectoEn)
        );
      }
    } else if (lang === 'es') {
      this.proyecto = Object.assign({}, this.proyectoEs);
    }
  }

  scrollTop(){
    document.getElementById('proyecto__scrollable').scroll({top: 0, behavior: 'smooth'});
  }

  fullImage(src){
    this.fullImageContainer = true;
    this.fullImageSrc = src;
  }

  closeFullImage(){
    const wasOpen = this.fullImageContainer;
    this.fullImageContainer = false;
    if (wasOpen){
      setTimeout(() => {
        document.getElementById('proyecto__scrollable').scroll({top: this.scrollPosition});
      }, 200);
    }
  }

  @HostListener('document:wheel', ['$event'])
  @HostListener('document:scroll', ['$event'])
  @HostListener('document:keyup', ['$event']) onMove(e) {
    this.closeFullImage();
  }

  doSomethingOnScroll($event){
    if ($event.srcElement.scrollTop > 0 && !this.fullImageContainer){
      this.scrollPosition = $event.srcElement.scrollTop;
    }
  }
}
