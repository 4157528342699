<p-panel #pnl header="Traducciones" styleClass="p-mt-4 app__scrollable">
    <div  class="grid-x grid-margin-y grid-padding-x">
        <div class="cell small-12 ">
            <p-selectButton *ngIf="filteredItems; else loadingIcon" [options]="filtros" [(ngModel)]="filtro" (onChange)="filterChange()"></p-selectButton>
            <ng-template #loadingIcon><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></ng-template>
        </div>
    </div>
    <div class="grid-x grid-margin-y grid-padding-x ">
        <div *ngFor="let item of filteredItems" class="cell">
            <form class="grid-x grid-margin-y grid-padding-x align-right ui-fluid ">
                <div class="cell small-12 large-5 ">
                    <p-editor *ngIf="item.campo.html; else simpleEditor" name="campo" [(ngModel)]="item.campo.contenido" [readonly]="true" [style]="{'height':'200px'}">
                        <p-header><strong style="text-transform: capitalize; color: #fff;">{{item.campo.nombre}}</strong></p-header>
                    </p-editor>
                    <ng-template #simpleEditor><input pInputText name="campo" disabled [(ngModel)]="item.campo.contenido" /></ng-template>
                </div>
                <div class="cell small-12 large-6 ">
                    <p-editor *ngIf="item.campo.html; else simpleEditor2" name="contenido" [(ngModel)]="item.traduccion.contenido"
                        [readonly]="loading" [style]="{'height':'200px'}">
                    </p-editor>
                    <ng-template #simpleEditor2><input pInputText name="contenido" [(ngModel)]="item.traduccion.contenido" /></ng-template>
                </div>
                <div class="cell small-1">
                    <button type="button" pButton icon="pi pi-check" (click)="save(item)"></button>
                </div>
            </form>
        </div>
    </div>
</p-panel>
