<h1 #tituloChicas  class="proyecto__titulo proyecto__titulo--stiky show-for-small-only">{{proyecto?.titulo}}</h1>

<div class="proyecto grid-x grid-margin-x app__scrollable" id="proyecto__scrollable" (scroll)="doSomethingOnScroll($event)"
    [ngStyle]="{'height': 'calc( 100% - '+  tituloChicas.offsetHeight + 'px - 10vh)'}"
>
    
    <div class="cell medium-6 small-order-1 medium-order-2 proyecto__fotos" [ngClass]="{'proyecto__fotos--hide': fullImageContainer}" >
        <div class="proyecto__full-image" [@fullImageContainer]="fullImageContainer ? 'open' : 'closed'"  (click)="closeFullImage()">
            <img *ngIf="fullImageContainer"  [src]="fullImageSrc" [alt]="proyecto?.titulo">
        </div>
        <div class="grid-x grid-margin-y">
            <div class="cell" *ngFor="let foto of proyecto?.fotos">
                <img  class="proyecto__foto" [src]="foto" [alt]="proyecto?.titulo" (click)="fullImage(foto)" >
            </div>
        </div>
    </div>

    <div class="cell medium-6 small-order-2 medium-order-1" (click)="closeFullImage()"  [ngClass]="{'hide-for-small-only': fullImageContainer}">
        <div class="grid-x grid-margin-y proyecto__texto">
            <div class="cell">
                <h1 class="proyecto__titulo show-for-medium">{{proyecto?.titulo}}</h1>
            </div>
            <div class="cell proyecto__datos" [ngClass]="{'hide-for-small-only': fullImageContainer}">
                <strong>
                    <span class="proyecto__date">{{proyecto?.date}}</span>
                    <div class="proyecto__descripcion app__quill-content" [innerHTML]="proyecto?.descripcion"></div>
                </strong>
            </div>
            <div class="cell" [ngClass]="{'hide-for-small-only': fullImageContainer}">
                <div class="proyecto__info app__quill-content" [innerHTML]="proyecto?.info"></div>
            </div>
        </div>
    </div>

</div>

<div class="proyecto__up-button-container show-for-medium">
    <button class="proyecto__up-button" type="button" (click)="scrollTop()" >
        <i class="pi pi-arrow-up"></i>
    </button>
</div>