import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoComponent } from './info/info.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { ContactoComponent } from './contacto/contacto.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { httpTranslateLoader } from '../app.module';



@NgModule({
  declarations: [InfoComponent, NoticiasComponent, ContactoComponent],
  imports: [
      CommonModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpTranslateLoader,
          deps: [ HttpClient ]
        }
      })
  ],
  exports: [
    TranslateModule
  ]
})
export class SidebarModule { }
