import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  private getHeader(addToken): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('client_id', environment.client_id);
    if (addToken) {
      headers = headers.append('Authorization', `Bearer ${localStorage.palma_token}`);
    }
    return headers;
  }

  public get(url, endPoint, addToken) {
    const headers = this.getHeader(addToken);
    return this.http.get(`${url + endPoint}`, { headers });
  }

  public post(url, endPoint, data, addToken) {
    const headers = this.getHeader(addToken);
    return this.http.post(`${url + endPoint}`, data, { headers });
  }

  public put(url, endPoint, data, addToken) {
    const headers = this.getHeader(addToken);
    return this.http.put(`${url + endPoint}`, data, { headers });
  }

  public patch(url, endPoint, data, addToken) {
    const headers = this.getHeader(addToken);
    return this.http.patch(`${url + endPoint}`, data, { headers });
  }

  public delete(url, endPoint, addToken) {
    const headers = this.getHeader(addToken);
    return this.http.delete(`${url + endPoint}`, { headers });
  }

  public getBlob(url, endPoint, addToken) {
    return this.http.get(`${url + endPoint}`, {
      responseType: 'blob', headers: new HttpHeaders({ Authorization: `Bearer ${localStorage.palma_token}` })
    });
  }

  public getLanguages() {
    return this.http.get('../../assets/language.json');
  }
}
