export enum ResponseCode {
    'OK'= 'OK',
    'CREATED'= 'CREATED',
    'BAD_REQUEST_ERROR'= 'BAD_REQUEST_ERROR',
    'UNAUTHORIZED_ERROR'= 'UNAUTHORIZED_ERROR',
    'REQUEST_FAILED_ERROR'= 'REQUEST_FAILED_ERROR',
    'FORBIDDEN'= 'FORBIDDEN',
    'NOT_FOUND_ERROR'= 'NOT_FOUND_ERROR',
    'CONFLICT_ERROR'= 'CONFLICT_ERROR',
    'TOO_MANY_REQUESTS_ERROR'= 'TOO_MANY_REQUESTS_ERROR',
    'SERVER_ERROR'= 'SERVER_ERROR',
    'API_CONNECTION_ERROR'= 'API_CONNECTION_ERROR',
    'UNPROCESSABLE_ENTITY'= 'UNPROCESSABLE_ENTITY'
}
