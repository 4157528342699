import * as S3 from 'aws-sdk/clients/s3';
import { Portada } from 'src/app/interfaces/portada';
import { PortadaService } from 'src/app/services/portada.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ResponseCode } from 'src/app/enum/response-code.enum';
import { S3Object } from 'src/app/interfaces/s3object';
import { SelectItem } from 'primeng/api';
import { SeoService } from 'src/app/services/seo.service';
import { UploadService } from 'src/app/services/upload.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-portadas',
  templateUrl: './portadas.component.html',
  styleUrls: ['./portadas.component.scss']
})
export class PortadasComponent implements OnInit {

  cols: any[];
  data: Portada[];
  displayDialog: boolean;
  errorMessage: any;
  exportColumns: any[];
  filteredResults: S3Object[];
  imageSearchText: string;
  item: Portada;
  loading = false;
  newClase: boolean;
  nuevoBeneficio: string;
  procedimientoReadonly = true;
  s3objects: S3Object[];
  selectedData: Portada;
  sortKey: string;
  sortOptions: SelectItem[];

  @ViewChild('portadaUpload') portadaUpload: any;
  @ViewChild('fotosUpload') fotosUpload: any;

  constructor(public userService: UserService,
    private seoService: SeoService,
    private uploadService: UploadService,
    private dataService: PortadaService) { }

  ngOnInit(): void {
    this.seoService.setTitle('Palma | Administrador');
    this.getAll();

    this.cols = [
      { field: 'thumbnail', header: 'Portada' },
      { field: 'vertical', header: 'Vertical' },
      { field: 'oculto', header: 'Oculta' },
      { field: '_id', header: 'Eliminar' },
    ];

    const user = this.userService.user;

    if (!user) {
      console.error('Unatorized access.');
    }

    const bucket = new S3(
      {
        accessKeyId: 'AKIA' + user?.credentials?.b,
        secretAccessKey: user?.credentials?.a?.split('').reverse().join('')
      }
    );

    const params = {
      Bucket: 'palma-assets',
    };

    const onListObjects = (err, s3data) => {
      if (err) {
        console.error('Error', err);
      } else {
        this.s3objects = s3data.Contents;
      }
    };

    bucket.listObjects(params, onListObjects);
  }

  getAll() {
    this.dataService.getAll().subscribe((res: any) => {
      const { content, messageCode } = res;
      if (messageCode && messageCode === ResponseCode.OK) {
        this.data = content;
      }
    }, (err) => {
      const { message, messageCode } = err.error;
      this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
    });
  }

  // Edit form.
  async save() {
    const data = [...this.data];

    // Upload images
    if (this.portadaUpload._files.length) {
      this.loading = true;
      const file = this.portadaUpload._files[0];
      const promises = [];
      promises.push(
        this.uploadService.uploadFile(file, 'portadas').then((response: any) => {
          const original = response.url;
          const thumbnail = this.uploadService.getVariant(response.key, { resize: 'cover', width: 100, height: 70 });
          this.item.original = original;
          this.item.thumbnail = thumbnail;
          this.portadaUpload.clear();
        }, err => {
          console.error(err);
          this.loading = false;
        })
      );

      await Promise.all(promises).then(values => {
        this.portadaUpload.clear();
        this.loading = false;
      });
    }

    // Save New
    if (this.newClase) {
      this.dataService.save(this.item).subscribe((res: any) => {
        const {
          content,
          messageCode
        } = res;
        if (messageCode && messageCode === ResponseCode.OK) {
          data.push(content.content);
          this.data = data;
          this.item = null;
          this.displayDialog = false;
        }
      }, (err) => {
        const {
          message,
          messageCode
        } = err.error;
        this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
      });
    } else {

      // Update
      this.dataService.update(this.item._id, this.item).subscribe((res: any) => {
        data[this.data.indexOf(this.selectedData)] = this.item;
        this.data = data;
      }, (err) => {
        this.getAll();
        const {
          message,
          messageCode
        } = err.error;
        this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
      });

      this.displayDialog = false;
    }
  }

  deleteById(id) {
    this.dataService.delete(id).subscribe((res: any) => {
      this.data = this.data.filter((item) => item._id !== id);
    }, (err) => {
      this.data = this.data.filter((item) => item._id !== id);
      const {
        message,
        messageCode
      } = err.error;
      this.errorMessage = messageCode === ResponseCode.SERVER_ERROR ? 'Ups, algo salio mal...' : message;
    });
  }

  delete() {
    this.deleteById(this.item._id);
    this.displayDialog = false;
  }

  showDialogToAdd() {
    this.newClase = true;
    this.item = {
    } as unknown as Portada;

    this.displayDialog = true;
  }

  onRowSelect(event) {
    this.newClase = false;
    this.item = this.cloneReceta(event.data);
    this.displayDialog = true;
  }

  cloneReceta(c: Portada): Portada {
    const receta = {} as Portada;

    for (const prop in c) {
      if (c.hasOwnProperty(prop)) {
        receta[prop] = c[prop];
      }
    }

    return receta;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  // Prevent editor autofocus
  detalleOnShow() {
    this.procedimientoReadonly = false;
  }

  // Image management
  onUpload(event) {
    for (const file of event.files) {
      this.uploadService.uploadFile(file, 'crud-test').then((data: any) => {
        const original = data.url;
        const thumbnail = this.uploadService.getVariant(data.key, { resize: 'cover', height: 70, width: 100 });
        this.item.original = original;
        this.item.thumbnail = thumbnail;

      }, err => {
        console.error(err);
      });
    }

    this.portadaUpload.clear();
  }

  search($event) {
    this.filteredResults = [];
    for (const s3object of this.s3objects) {
      if (s3object.Key.toLowerCase().indexOf($event.query.toLowerCase()) !== -1) {
        this.filteredResults.push(s3object);
      }
    }
  }

  searchDone($event) {
    this.selectImage($event);
  }

  selectImage(image: S3Object) {
    const original = this.uploadService.getVariant(image.Key, {});
    const thumbnail = this.uploadService.getVariant(image.Key, { resize: 'cover', height: 70, width: 100 });
    this.item.original = original;
    this.item.thumbnail = thumbnail;
  }

  onSortChange() {
    if (this.sortKey.indexOf('!') === 0) {
      this.sort(-1);
    }
    else {
      this.sort(1);
    }
  }

  sort(order: number): void {
    const s3objects = [...this.s3objects];
    const property = this.sortKey.replace('!', '');
    s3objects.sort((data1, data2) => {
      const value1 = data1[property];
      const value2 = data2[property];
      const result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      return (order * result);
    });

    this.s3objects = s3objects;
  }

}
