<div id="listado-proyectos" class="listado-proyectos grid-x grid-margin-x cell-block-y scrollable-container align-top">
  <div class="cell medium-6">
    <div class=" grid-x grid-margin-y">
      <div *ngFor="let group of groups" class="listado-proyectos__group cell">
        <div class=" grid-x grid-margin-x">
          <div class="cell shrink">
            <div #spacer></div>
            <h1 stickyThing [spacer]="spacer" [scrollContainer]="'.scrollable-container'" [id]="'group_'+group" class="listado-proyectos__date">{{group}}</h1>
          </div>
          <div class="cell auto  listado-proyectos__info">
            <div class=" grid-x grid-margin-y">
              <a [routerLink]="item.slug" [state]="{ data: item}" *ngFor="let item of data | propertyFilter:{property: 'date', value: group}"
                class="listado-proyectos__item cell" (mouseover)="selectedItemId = item._id"
                (mouseleave)="selectedItemId = ''">
                <img class="show-for-small-only listado-proyectos__portada-small" [src]="item.portada.original"
                  [alt]="item.titulo">
                <strong class="listado-proyectos__titulo">{{item.titulo}}</strong>
                <div class="listado-proyectos__descripcion app__quill-content" [innerHTML]="item.descripcion"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div  class="listado-proyectos__portadas cell medium-6 hide-for-small-only">
    <img class="listado-proyectos__portada" *ngFor="let item of data" [ngClass]="{'hide': selectedItemId !== item._id}"
      [src]="item.portada.original" [alt]="item.titulo">
  </div>
</div>
