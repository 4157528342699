import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoticiasAdminComponent } from './noticias/noticias.component';
import { PortadasComponent } from './portadas/portadas.component';
import { InfoAdminComponent } from './info/info.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ContactoAdminComponent } from './contacto/contacto.component';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProyectosAdminComponent } from './proyectos/proyoectos.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TraduccionesComponent } from './traducciones/traducciones.component';
import { VirtualScrollerModule } from 'primeng/virtualscroller';


@NgModule({
  declarations: [
    InfoAdminComponent,
    NoticiasAdminComponent,
    PortadasComponent,
    ProyectosAdminComponent,
    ContactoAdminComponent,
    TraduccionesComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    // primeng
    AccordionModule,
    AutoCompleteModule,
    BlockUIModule,
    ButtonModule,
    CalendarModule,
    CommonModule,
    ContextMenuModule,
    DialogModule,
    DropdownModule,
    EditorModule,
    FileUploadModule,
    InputMaskModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    MultiSelectModule,
    OrderListModule,
    PanelModule,
    ProgressBarModule,
    SelectButtonModule,
    SliderModule,
    TableModule,
    ToastModule,
    TooltipModule,
    VirtualScrollerModule,
  ]
})
export class AdminModule { }
